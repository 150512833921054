import React, { useRef } from 'react';
import { Col, Image, Nav, Row, Tab } from 'react-bootstrap';
import { HowItWorksContent } from '../definitions/HowItWorksContent';
import styles from "./HowItWorksPresenterWithTabs.module.css";

function HowItWorksPresenterWithTabs(props: { content: HowItWorksContent[] }) {
    const tabContentRef = useRef<null | HTMLDivElement>(null);

    const ScrollToTabContent = () => {
        if (tabContentRef && tabContentRef.current) {
            tabContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <div className="py-4">
            <div className={styles.wrapper}>
                <Tab.Container defaultActiveKey="0">
                    <Row>
                        <Col md={3}>
                            <Nav variant="tabs" className="flex-column h-100">
                                {props.content.map((item, index) =>
                                    <Nav.Item key={index}>
                                        <Nav.Link className={`${index === 0 ? 'border-top-right-radius-md-0' : ''}`} eventKey={index} onClick={ScrollToTabContent}>{item.tabName}</Nav.Link>
                                    </Nav.Item>
                                )}
                                <Nav.Item className="tab-filler"></Nav.Item>
                            </Nav>
                        </Col>
                        <Col md={9} className='ps-md-0'>
                            <Tab.Content className='h-100' ref={tabContentRef}>
                                {props.content.map((item, index) =>
                                    <Tab.Pane key={index} eventKey={index}>
                                        {item.noOverlayFullWidthImage ?
                                            <Row className="h-100">
                                                <Col className='text-center d-flex flex-column'>
                                                    <h4>{item.title}</h4>
                                                    <div className='secondary-text mt-4'>{item.subtitle}</div>
                                                    <Row className='pt-3'>
                                                        <Col className="position-relative">
                                                            <Image src={item.image} className={`${styles.noOverlayFullWidthImage} full-size`} />
                                                        </Col>
                                                    </Row>
                                                    <ul className="primary-color-markers mt-4 mb-2 text-start mx-auto">
                                                        {item.list.map((listItem, index) => <li key={index} className="mb-2">{listItem}</li>)}
                                                    </ul>
                                                </Col>
                                            </Row>
                                            :
                                            <Row className="h-100">
                                                <Col md={8} className='text-center pe-md-3 d-flex flex-column'>
                                                    <h4>{item.title}</h4>
                                                    <div className='secondary-text mt-4'>{item.subtitle}</div>
                                                    <Row className='pt-3 pt-md-0'>
                                                        <Col className="position-relative">
                                                            <Image src={item.image} className={`${styles.smallContentImage} full-size d-md-none`} />
                                                            <div className={`${styles.smallContentImageOverlay} full-size`} />
                                                        </Col>
                                                    </Row>
                                                    <ul className="primary-color-markers mt-4 mb-2 text-start mx-auto">
                                                        {item.list.map((listItem, index) => <li key={index} className="mb-2">{listItem}</li>)}
                                                    </ul>
                                                </Col>
                                                <Col md={4} className='d-none d-md-block ps-md-3 position-relative'>
                                                    <Image src={item.image} className={`${styles.contentImage} full-size`} />
                                                    <div className={`${styles.contentImageOverlay} full-size`} />
                                                </Col>
                                            </Row>
                                        }
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    );
}

export default HowItWorksPresenterWithTabs;