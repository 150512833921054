import React, { useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import { RequestQuoteType } from '../services/RequestService';
import { RequestQuoteDialog } from './RequestQuoteDialog';

function PricingCard(props: { title: string, cost: string, perMonth?: boolean, animationDirection: any, onClick: (header: string) => {} }) {
    return (
        <div className="col-md-6">
            <Fade direction={props.animationDirection} triggerOnce>
                <div className="card mb-4 box-shadow">
                    <h4 className="mt-3 mb-0 font-weight-normal text-muted">{props.title}</h4>
                    <div className="card-body mt-0">
                        <h1 className="card-title pricing-card-title primary-text mt-1">{props.cost} {props.perMonth ? <small className="text-muted">/ month</small> : null}</h1>
                        <button type="button" className="btn btn-lg btn-block btn-outline-primary w-100 mt-3" onClick={() => props.onClick('Request a quote')}>Request a quote</button>
                    </div>
                </div>
            </Fade>
        </div>
    );
}

function Pricing() {
    let requestDialogRef = useRef(null as any);
    
    const showRequestDialog = (type: RequestQuoteType, header: string) => requestDialogRef.current.open(type, header);

    return (
        <div className="text-center">
            <div className="row">
                <PricingCard title="For teams" cost="$10" perMonth animationDirection='left' onClick={(header) => showRequestDialog(RequestQuoteType.RequestAQuoteForTeam, header)} />

                <PricingCard title="For enterprise" cost="Custom" animationDirection='right' onClick={(header) => showRequestDialog(RequestQuoteType.RequestAQuoteForEnterprise, header)} />

                <PricingCard title="For coaches" cost="Custom" animationDirection='left' onClick={(header) => showRequestDialog(RequestQuoteType.RequestAQuoteForCoaches, header)} />

                <div className="col-md-6">
                    <Fade direction='right' triggerOnce>
                        <div className="card mb-4 box-shadow">
                            <h4 className="mt-3 mb-0 font-weight-normal text-muted">Other options</h4>
                            <div className="card-body mt-0 d-flex flex-column">
                                <button type="button" className="btn btn-lg btn-block btn-outline-primary w-100 mt-auto" onClick={() => showRequestDialog(RequestQuoteType.RequestADemo, "Request a demo")}>Request a demo</button>
                                <button type="button" className="btn btn-lg btn-block btn-outline-primary w-100 mt-3" onClick={() => showRequestDialog(RequestQuoteType.ContactUs, "Contact us")}>Contact us</button>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>

            <RequestQuoteDialog ref={requestDialogRef} />
        </div>
    );
}

export default Pricing;