import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import { PrivacyPolicyPageURL, TermsAndConditionsPageURL } from './definitions/routes';

function App() {
  return (
    <main className="app-wrapper">
      <Header />
      <Routes>
        <Route path={PrivacyPolicyPageURL} element={<PrivacyPolicyPage/>} />
        <Route path={TermsAndConditionsPageURL} element={<TermsAndConditionsPage/>} />
        <Route path="/" element={<HomePage/>}/>
        <Route path="*" element={<div/>} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
