import config from "../config";

export const RequestQuote = async (data: { type: RequestQuoteType, name: string, email: string, details: string }) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const result = await fetch(`${config.apiUrl}/requestQuote`, requestOptions);
        return result.status === 200;
    }
    catch (ex) {
        console.error(ex);
        return false;
    }
}

export enum RequestQuoteType {
    RequestAQuoteForTeam = "RequestAQuoteForTeam",
    RequestAQuoteForEnterprise = "RequestAQuoteForEnterprise",
    RequestAQuoteForCoaches = "RequestAQuoteForCoaches",
    RequestADemo = "RequestADemo",
    ContactUs = "ContactUs"
}