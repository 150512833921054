import React from 'react';
import HowItWorksPresenterWithTabs from '../components/HowItWorksPresenterWithTabs';
import Phone from '../resources/phone.png';
import downloadIOS from '../resources/downloadIOS.png';
import downloadGooglePlay from '../resources/downloadGooglePlay.png';
import Pricing from '../components/Pricing';
import { Zoom, Fade } from 'react-awesome-reveal';
import { Solutions } from '../content/Solutions';
import SolutionsCard from '../components/SolutionsCard';
import { HowItWorks } from '../content/HowItWorks';

function HomePage() {
    return (
        <div>
            <section className='paralax-image' id="home">
                <div className='paralax-image-overflow'>
                    <div className='container home-section'>
                        <div className='row text-center text-md-start'>
                            <div className='col-md-6 col-lg-5 offset-lg-1 my-auto'>
                                <h2 className='mb-4'>TEAMWORK MAKES THE DREAMWORK</h2>
                                <div>At all times your company success depends on the level of people development, their competency, collaboration and diversity.</div>
                            </div>
                            <Fade direction='up' triggerOnce fraction={0} duration={1200} className='col-md-6 col-lg-6 overflow-hidden align-self-end'>
                                <img className='img-fluid phone-image' style={{height:'580px'}} src={Phone} alt="phone" />
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section id="solutions">
                <div className='container solutions-section'>
                    <h2>Solutions</h2>
                    <div className='row justify-content-center mt-3'>
                        {Solutions.map((solution, index) => <SolutionsCard key={index} content={solution} />)}
                    </div>
                </div>
            </section>

            <section className="paralax-image" id="howitworks">
                <div className='paralax-image-overflow'>
                    <div className='container'>
                        <div className='px-2 howitworks-section text-center'>
                            <h2>How it works</h2>
                            <Zoom triggerOnce >
                                <HowItWorksPresenterWithTabs content={HowItWorks} />
                            </Zoom>
                        </div>
                    </div>
                </div>
            </section>

            <section id="download">
                <div className='container download-section pt-5 pb-5 text-center'>
                    <Fade direction='down' triggerOnce>
                        <h1><strong className='secondary-text'>Download and try</strong> MetUp for free!</h1>
                        <div className='mt-3 mb-4'>Download MetUp and register your team. Get 30 days free trial period with full functionality available.</div>
                        <div className="row">
                            <div className='col-md-6 d-flex justify-content-center'>
                                <a href='https://apps.apple.com/us/app/metup/id1661960080' className="ms-md-auto">
                                    <img src={downloadIOS} className='download-link-image mb-3' />
                                </a>
                            </div>
                            <div className='col-md-6 d-flex justify-content-center'>
                                <a href='https://play.google.com/store/apps/details?id=com.metup' className='me-md-auto'>
                                    <img src={downloadGooglePlay} className='download-link-image' />
                                </a>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>

            <section className="paralax-image" id="pricing">
                <div className='paralax-image-overflow'>
                    <div className='container pricing-section text-center px-3'>
                        <h2 className='pb-4'>Pricing</h2>
                        <Pricing />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HomePage;