import { HowItWorksContent } from "../definitions/HowItWorksContent";
import tab1Image from '../resources/content-1-low-res.jpg';
import tab2Image from '../resources/content-2-low-res.jpg';
import tab3Image from '../resources/content-3-low-res.jpg';
import tab4Image from '../resources/content-4-low-res.jpg';
import tab5Image from '../resources/content-5-low-res.jpg';
import tab6Image from '../resources/content-6-low-res.jpg';

export const HowItWorks: HowItWorksContent[] = [
    {
        tabName: "Diagnoze team development stage",
        title: "Discover your team development stage through Tuckman test",
        subtitle: "Keep your hand on the pulse of continuous team development and issues",
        list: [
            "Register your team in MetUp",
            "Ask each team member to take Tuckman test",
            "Learn where your are and jump into tools for your team stage"
        ],
        image: tab1Image,
        noOverlayFullWidthImage: true
    },
    {
        tabName: "Integrate team",
        title: " Get to know everyone in your team",
        subtitle: "Real time introduction to each colleague personolized profile",
        list: [
            "Ask your team to take Enneargam test and put personal notes to methodology description",
            "Put your own personal notes about how you wish to collaborate in appropriate sections",
            "Study your colleagues profiles and personal notes from them, ask questions in profile section, make notes to use at work"
        ],
        image: tab2Image
    },
    {
        tabName: "Increase collaboration",
        title: "Learn strengths and risks working with colleagues and create collaboration agreement",
        subtitle: "Study real-time collaboration maps for any pair of colleagues in team, both now and overtime dynamics",
        list: [
            "Choose a colleague with whom you want to build better relations in Collaboration Map",
            "Mark your current level of collaboration in color codes and learn the same from your colleague",
            "Learn strenths, risks and recommendations for you working together, build on that from your personal experience, discuss with your peer and creat collaboration agreement"
        ],
        image: tab3Image
    },
    {
        tabName: "Optimize team effectiveness",
        title: "Find out teams role distribution through  Belbin team test",
        subtitle: "Discover recommendations for getting more efficiency in your team in real time diagnostics",
        list: [
            "Ask your team to take Belbin team role test and study your development recomendations",
            "All colleagues to vote for one of top ten common issues in the teams and learn how to bridge identified issue with roles re-distribution",
            "When building a new project team, make sure all key roles are covered"
        ],
        image: tab4Image
    },
    {
        tabName: "Integrate team motivation",
        title: "Discover different types of motivation in your team",
        subtitle: "Learn recommendations to balance team motivation for sustainable development",
        list: [
            "Ask your team to fill in Motivation questionnaire",
            "Discover recommendations for risks - success drivers balance management based on teams questionnaire results",
            "Learn and itegrate personal motivations of your team members"
        ],
        image: tab5Image
    },
    {
        tabName: "Create shared values",
        title: "Learn your team personal relation to corporate values",
        subtitle: "Get an instant glance of what's important to your team members",
        list: [
            "Ask your team to evaluate corporate values through their own values",
            "Get in instant view on what really matters to your team members",
            "Integrate each peer values into team values map"
        ],
        image: tab6Image
    },
]