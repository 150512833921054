import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Zoom } from 'react-awesome-reveal';
import { SolutionContent } from '../definitions/SolutionContent';
import { Link } from 'react-router-dom';

function learnMorePopover(learnMoreContent: string[]) {
    return (
        <Popover id="popover-basic" className='learn-more-popover'>
            <Popover.Body>
                <ul className='primary-color-markers'>
                    {learnMoreContent.map((content, index) => <li key={index}>{content}</li>)}
                </ul>
            </Popover.Body>
        </Popover>
    );
}

export default function SolutionsCard(props: { content: SolutionContent }) {
    return (
        <div className='col-md-4 p-3 scrollable' id={props.content.to}>
            <Zoom triggerOnce>
                <div>
                    <props.content.icon size={50} color="var(--primary-color)" />
                    <h4>{props.content.title}</h4>
                    <div>{props.content.text}</div>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={learnMorePopover(props.content.learnMore)} rootClose>
                        <Link to="">learn more</Link>
                    </OverlayTrigger>
                </div>
            </Zoom>
        </div>
    )
}