import React from 'react';
import { MdOutlineHome, MdCall, MdMailOutline } from "react-icons/md";
import { PrivacyPolicyPageURL, TermsAndConditionsPageURL } from '../definitions/routes';
import { Slide } from 'react-awesome-reveal';
import { HashLink } from 'react-router-hash-link';
import { Solutions } from '../content/Solutions';

function Footer() {
  return (
    <footer className='container py-5 scrollable' id="contactus">
        <div className="row text-center text-md-start footer-wrapper justify-content-md-between">
            <Slide direction='left' triggerOnce className="col-md-6">
                <div className=' d-flex align-items-center align-items-md-start flex-column'>
                    <h4>CONTACT US</h4>
                    <div className='my-3'>METUPPLUS LTD</div>
                    <div className='d-flex mb-1'><MdOutlineHome size={18} />Address: Cyprus, 3035 Limassol, Solonos, 20 20</div> 
                    <div className='d-flex mb-1'><MdCall size={18} />Phone Number:&ensp;<a href='tel:+35797969170'><strong>+357 97969170</strong></a></div>
                    <div className='d-flex'><MdMailOutline size={18} />Email:&ensp;<a href='mailto:info@metup.app'><strong>info@metup.app</strong></a></div>
                </div>
            </Slide>

            <Slide direction='left' triggerOnce className='col-md-3 mt-5 mt-md-0'>
                <div>
                <h5 className='mb-3'>SOLUTIONS</h5>
                {Solutions.map((solution, index) => 
                    <HashLink key={index} scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} to={`/#${solution.to}`} className='d-block mt-1 mb-1'>{solution.title}</HashLink>    
                )}
                </div>
            </Slide>
            
            <Slide direction='left' triggerOnce className='col-md-2 mt-5 mt-md-0'>
                <div>
                <h5>TERMS AND CONDITIONS</h5>
                <HashLink smooth={true} to={`${PrivacyPolicyPageURL}#`} preventScrollReset={false} className='d-block mt-3 mb-1'>Privacy policy</HashLink>
                <HashLink smooth={false} to={`${TermsAndConditionsPageURL}#`} className='d-block'>Terms and Conditions</HashLink>
                </div>
            </Slide>

            {/* <Slide direction='left' triggerOnce className='col-md-2 mt-5 mt-md-0'>
                <div>
                <h5>ABOUT US</h5>
                <HashLink to="/#contactus" className='d-block mt-3 mb-1'>Contacts</HashLink>
                </div>
            </Slide> */}
        </div>
    </footer>
  );
}

export default Footer;