type Config = {
    apiUrl: string;
}

const DEV_config: Config = {
    apiUrl: 'http://127.0.0.1:8080'
}

const PROD_config: Config = {
    apiUrl: ''
}

export default {
    ...(process.env.NODE_ENV === 'development' ? DEV_config : PROD_config)
}