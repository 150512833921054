import { MdSelfImprovement, MdOutlineGroups, MdOutlineBusinessCenter, MdOutlineHowToReg, MdGroupAdd, MdOutlineModelTraining, MdOutlinedFlag } from "react-icons/md";
import { SolutionContent } from "../definitions/SolutionContent";

export const Solutions: SolutionContent[] = [
    {
        icon: MdSelfImprovement,
        title: "For better selves",
        text: "Learn your Soft Skills peculiarity and developement track",
        learnMore: [ 
            "Take a test on personality type and learn your possible strengths and development areas in Soft Skills distribution",
            "Get a better view at yourself with on-line feedback from your colleages on your type and particular Soft Skills traits",
            "Use learning sections to develop your Soft Skills"
        ],
        to: "for-better-selves"
    },
    {
        icon: MdOutlineGroups,
        title: "For business teams",
        text: "Get most effectiveness and joy working together",
        learnMore: [ 
            "Diagnose your team development stage through Tuckman methodology",
            "Apply on-line tools relevant for each stage",
            "Check your team progress every month"
        ],
        to: "for-business-teams"
    },
    {
        icon: MdOutlineBusinessCenter,
        title: "For Enterprices",
        text: "Provide equal supreme access to development of all employees and teams",
        learnMore: [ 
            "Choose the services from the platform based on the company people strategy and needs",
            "Integrate the services to your LMS or activate corporate account in MetUp server",
            "Activate employees engagement into development process through blended learning courses"
        ],
        to: "for-enterprise"
    },
    {
        icon: MdOutlineHowToReg,
        title: "For HR",
        text: "Leverage culture fit and job profile match for newhires and employees",
        learnMore: [ 
            "Import to MetUp the values of your company",
            "Study how employees and candidates personally feel about your company values",
            "Discover real-time personal values blend for your organization to use it for people engagement strategy."
        ],
        to: "for-hr"
    },
    {
        icon: MdOutlineModelTraining,
        title: "For coaches",
        text: "Take your coaching to the next level",
        learnMore: [ 
            "Register your team in MetUp platform in their own account",
            "Get more that 20 coaching sessions designs with practical exercises from MetUp",
            "Partner with MetUp in platform integration to your customers"
        ],
        to: "for-coaches"
    },
]