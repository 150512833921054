import React from 'react';
import logo from '../resources/logo.svg';
import brand from '../resources/brand.svg';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <Navbar className='header-container' collapseOnSelect expand="md" fixed='top' id="navbar">
            <Container>
                <Navbar.Brand>
                    <Link reloadDocument to="/">
                        <img src={logo} className="header-logo me-4" alt="logo" />
                        <img src={brand} className="header-brand" alt="brand" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className='mt-2 mt-md-0' id="responsive-navbar-nav">
                    <Nav className="ms-auto align-items-center">
                        <Nav.Link as={HashLink} to="/#home" href="/#home">HOME</Nav.Link>
                        <Nav.Link as={HashLink} to="/#solutions" href="/#solutions">SOLUTIONS</Nav.Link>
                        <Nav.Link as={HashLink} to="/#howitworks" href="/#howitworks">HOW IT WORKS</Nav.Link>
                        <Nav.Link as={HashLink} to="/#download" href="/#download">DOWNLOAD</Nav.Link>
                        <Nav.Link as={HashLink} to="/#pricing" href="/#pricing">PRICING</Nav.Link>
                        <Nav.Link as={HashLink} to="#contactus" href="#contactus">CONTACT US</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;