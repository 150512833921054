import React, { useImperativeHandle, useState } from "react";
import { Modal, Form, Spinner, Alert } from "react-bootstrap";
import { BsCheck2 } from 'react-icons/bs';
import { RequestQuote, RequestQuoteType } from "../services/RequestService";

type RequestQuoteDialogState = {
    show?: boolean,
    name?: string,
    email?: string,
    details?: string,
    header?: string,
    type?: RequestQuoteType,
    isLoading?: boolean,
    loaded?: boolean,
    errorMsg?: string
}

export const RequestQuoteDialog = React.forwardRef((props, ref) => {
    const [dialogState, setDialogState] = useState({ show: false, name: '', email: '', details: '', header: '', isLoading: false, loaded: false, errorMsg: '' } as RequestQuoteDialogState);

    const handleRequest = async(event: React.FormEvent) => {
        event.preventDefault();
        if (dialogState.type && dialogState.name && dialogState.email && dialogState.details)
        {
            startLoading();
            if (await RequestQuote({ type: dialogState.type, name: dialogState.name, email: dialogState.email, details: dialogState.details }))
                endLoading();
            else
                errorHappened("Failed to send the request!", { isLoading: false });
        } else {
            errorHappened("Something went wrong!");
        }
    }

    useImperativeHandle(ref, () => ({
        open: (type: RequestQuoteType, header: string) => {
            show({ name: '', email: '', details: '', type, header, isLoading: false, loaded: false, errorMsg: '' });
        },
        close: () => {
            hide();
        }
    }));

    const setName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDialogState((curState) => ({ ...curState, name: event.target.value }));
    const setEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDialogState((curState) => ({ ...curState, email: event.target.value }));
    const setDetails = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDialogState((curState) => ({ ...curState, details: event.target.value }));
    const show = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, show: true }));
    const hide = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, show: false }));
    const startLoading = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, isLoading: true }));
    const endLoading = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, isLoading: false, loaded: true }));
    const errorHappened = (message: string, updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, errorMsg: message }));

    return (
        <Modal ref={ref} show={dialogState.show} centered onHide={hide}>
            <Modal.Header className="primary-text p-4 pb-2" closeButton style={{ borderBottom: 0, fontWeight: 300 }}>
                <Modal.Title><h4>{dialogState.header?.toUpperCase()}</h4></Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-4 pt-2'>
                {dialogState.loaded ?
                    <div className="text-center pt-2 pb-3"><BsCheck2 size={30} className='primary-text me-2' />Your request has been successfully sent!</div>
                :
                    <Form className={`${dialogState.isLoading ? 'invisible' : ''}`} onSubmit={handleRequest}>
                        {dialogState.errorMsg ? <Alert variant='danger'>{dialogState.errorMsg}</Alert> : null}
                        <Form.Group className='mb-3' controlId="exampleForm.ControlInput1">
                            <Form.Label>Name<span style={{color: 'var(--bs-danger)'}}>*</span></Form.Label>
                            <Form.Control placeholder="Please enter your name" value={dialogState.name} onChange={setName} />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId="exampleForm.ControlInput1">
                            <Form.Label>Email<span style={{color: 'var(--bs-danger)'}}>*</span></Form.Label>
                            <Form.Control type='email' placeholder="Please enter your email" value={dialogState.email} onChange={setEmail} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Details<span style={{color: 'var(--bs-danger)'}}>*</span></Form.Label>
                            <Form.Control placeholder="Please provide details of the request" as="textarea" rows={3} value={dialogState.details} onChange={setDetails} />
                        </Form.Group>
                        <button type='submit' className="btn btn-lg btn-block btn-outline-primary w-100 mt-auto" disabled={!dialogState.name || !dialogState.details || !dialogState.email}>
                            Send request
                        </button>
                    </Form>
                }
                {dialogState.isLoading ? 
                    <div className="position-absolute top-50 start-50 translate-middle primary-text">
                        <Spinner />
                    </div>
                : null}
            </Modal.Body>
        </Modal>
    );
});